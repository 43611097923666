import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../../app/modules/auth'
import { useMainContext } from '../../../../context'
import { AsideMenuItem } from './AsideMenuItem'
export const AsideMenuMain: React.FC = () => {
  const queryClient = useQueryClient()
  const [isToggled, setIsToggled] = useState(false)
  const { companyofPortal, setCompanyonPortal, setAllCompanies } = useMainContext()
  const { currentUser } = useAuth()
  const navigate = useNavigate()
  const intl = useIntl()
  const [isDeactivatedSport, setIsDeactivatedSport] = useState<any>(false)

  const onToggle = () => {
    setIsToggled(!isToggled)
  }

  const fetchCompaniesOnclick = () => {
    queryClient.invalidateQueries(['get-getSportList', currentUser?.user?.organisation_id?._id])
  }

  useEffect(() => {
    setAllCompanies(currentUser?.user?.sports_list)
    for (let i = 0; i < currentUser?.user?.sports_list?.length; i++) {
      const item = currentUser?.user?.sports_list[i]
      if (item?._id === companyofPortal?.company_id) {
        setCompanyonPortal(item?._id, item?.nif_sports_category_id, item?.sports_category_name)
      } else if (currentUser?.user?.user_type === 'manager' && i === 0) {
        setCompanyonPortal(item?._id, item?.nif_sports_category_id, item?.sports_category_name)
      }
    }
  }, [currentUser?.user?.sports_list])

  useEffect(() => {
    const currentSport = currentUser?.user?.sports_list?.find(
      (sport: any) => sport._id === companyofPortal.company_id
    )
    setIsDeactivatedSport(currentSport?.status === 'disabled')
  }, [currentUser?.user?.sports_list, companyofPortal.company_id])

  const getInitials = (string: any) => {
    if (string) {
      const names = string?.split(' ')
      let initials = names[0]?.substring(0, 1)?.toUpperCase()
      if (names.length > 1) {
        initials += names[names.length - 1]?.substring(0, 1).toUpperCase()
      }
      return initials
    }
    return ''
  }

  const getShortName = (name: any) => {
    // eslint-disable-next-line eqeqeq
    if (name === 'Select Category') {
      name = currentUser?.user?.organisation_id?.org_name
    }

    if (name?.length > 20) {
      return `${name?.substring(0, 18)}...`
    }
    return name
  }

  return (
    <>
      <div className='menu-item'>
        <div className='menu-content  pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            {intl.formatMessage({ id: 'menue.unit' })}
          </span>
        </div>
      </div>
      <div className={isToggled ? 'select-menu active' : 'select-menu'}>
        <div
          className='select-btn'
          onClick={() => {
            onToggle()
            fetchCompaniesOnclick()
          }}
          style={{ width: '245px' }}
        >
          <span className='menu-icon'>
            <i className='fa-light fa-chart-network' />
          </span>
          <span className='sBtn-text'>
            {getShortName(companyofPortal?.name) + (isDeactivatedSport ? ' (Inaktiv)' : '')}
          </span>
          <svg
            className='sBtn-texticon'
            width='14'
            height='8'
            viewBox='0 0 14 8'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M1.82978 0.721191L6.681 5.04778L11.5322 0.721191L13.0225 2.05318L6.681 7.72119L0.339533 2.05318L1.82978 0.721191Z'
              fill='#001B5C'
            />
          </svg>
        </div>
        <ul className='options'>
          {currentUser?.user?.user_type !== 'manager' &&
            getShortName(companyofPortal?.name) !==
              currentUser?.user?.organisation_id?.org_name && (
              <li
                className='option'
                key={currentUser?.user?.organisation_id?._id}
                style={{ width: '245px' }}
                onClick={() => {
                  setIsToggled(!isToggled)
                  setCompanyonPortal(null, 'NO', currentUser?.user?.organisation_id?.org_name)
                  //If in home page then switch to dashboard
                  if (window.location.pathname === '/home') {
                    navigate('/dashboard')
                  }
                }}
              >
                <div className='symbol symbol-circle symbol-25px'>
                  <div className='text-capitalize symbol-label symbolChfsP fs-8 fw-bold bg-light-success-customshfs text-inverse-primary-customchfs'>
                    {getInitials(currentUser?.user?.organisation_id?.org_name)}
                  </div>
                </div>
                <span className='option-text'>{currentUser?.user?.organisation_id?.org_name}</span>
              </li>
            )}

          {currentUser?.user?.sports_list
            .sort((a, b) => b.sorting - a.sorting)
            ?.filter((item: any) => item?._id !== companyofPortal?.company_id)
            ?.map((item: any, i: any) => (
              <li
                className='option'
                key={item?._id}
                style={{ width: '245px' }}
                onClick={() => {
                  setIsToggled(!isToggled)
                  setCompanyonPortal(
                    item?._id,
                    item?.nif_sports_category_id,
                    item?.sports_category_name
                  )
                  localStorage.removeItem('division')
                  localStorage.setItem('division', item?.sports_category_name)
                  //If in dashboard then switch to home
                  navigate('/home')
                }}
              >
                <div className='symbol symbol-circle symbol-25px'>
                  <div className='text-capitalize symbol-label symbolChfsP fs-8 fw-bold bg-light-success-customshfs text-inverse-primary-customchfs'>
                    {getInitials(item?.sports_category_name)}
                  </div>
                </div>
                <span className='option-text'>
                  {item?.sports_category_name + (item?.status === 'disabled' ? ' (Inaktiv)' : '')}
                </span>
              </li>
            ))}
        </ul>
      </div>
      {isToggled && (
        <div
          className='drawer-overlay'
          onClick={() => setIsToggled(!isToggled)}
          style={{ cursor: 'pointer' }}
        />
      )}
      <div className='menu-item'>
        <div className='menu-content  pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            {intl.formatMessage({ id: 'dashboard' })}
          </span>
        </div>
      </div>
      <AsideMenuItem
        to={companyofPortal?.company_id === null ? 'dashboard' : '/home'}
        title={intl.formatMessage({ id: 'Overview' })}
        icon='fa-light fa-chart-simple'
      />
      {companyofPortal?.company_id === null && (
        <AsideMenuItem
          to='/org/sports/'
          title={intl.formatMessage({ id: 'units' })}
          icon='fa-light fa-diagram-subtask'
        />
      )}
      <AsideMenuItem
        to='/org/goals/'
        title={intl.formatMessage({ id: 'goals' })}
        icon='fa-light fa-bullseye-pointer'
      />
      <AsideMenuItem
        to='/org/support-player'
        title={intl.formatMessage({ id: 'support.players.small' })}
        icon='fa-light fa-people-group'
      />
      <AsideMenuItem
        to='org/campaign'
        title={intl.formatMessage({ id: 'campaign' })}
        icon='fa-light fa-calendar-star'
      />{' '}
      <AsideMenuItem
        to='org/recruitment'
        title={intl.formatMessage({ id: 'recruitment' })}
        icon='fa-light fa-calendar-star'
      />
      <AsideMenuItem
        to='/marketing'
        title={intl.formatMessage({ id: 'Marketing' })}
        hasBullet={false}
        icon='fa-light fa-megaphone'
        id='marketing'
      ></AsideMenuItem>
      {/*       <AsideMenuItem
        to='marketing/videos'
        title={intl.formatMessage({ id: 'goal.videos' })}
        icon='fa-light fa-video'
      /> */}
      <AsideMenuItem
        to='marketing/branding'
        title={intl.formatMessage({ id: 'Profile.material' })}
        icon='fa-light fa-image'
      />
      <AsideMenuItem
        to='marketing/studio'
        title={intl.formatMessage({ id: 'marketing.studio' })}
        icon='fa-light fa-palette'
      />
      <div className={clsx('menu-item my-1', 'menu-accordion')}>
        <a
          href='https://stotte.no/kalkulator'
          className={clsx('menu-link', 'menu-title')}
          target='_blank'
        >
          <span className='menu-icon'>
            <i className={clsx('', 'fa-light fa-calculator')} />
          </span>

          {intl.formatMessage({ id: 'calculator' })}
        </a>
      </div>
      {currentUser?.user?.user_type !== 'manager' && (
        <>
          <AsideMenuItem
            to='/org/users'
            id='orgUsers'
            title={intl.formatMessage({ id: 'USERS' })}
            icon='fa-light fa-users-medical'
          />
          <AsideMenuItem
            to='/org/reporting'
            id='orgReporting'
            title={intl.formatMessage({ id: 'reporting' })}
            icon='fa-light fa-file-spreadsheet'
          />
          {/*           <AsideMenuItem
            to='/org/editStatisticPage'
            id='orgStatistic'
            title='statistikk' //{intl.formatMessage({ id: 'statistic' })}
            icon='fa-light fa-file-spreadsheet'
          /> */}
          <AsideMenuItem
            id='orgSettings'
            to='/org/settings'
            title={intl.formatMessage({ id: 'Settings' })}
            icon='fa-light fa-sliders'
          />
        </>
      )}
    </>
  )
}
